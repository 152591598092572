import React from "react";
import ParadisoMap from "@/components/ui/VenueMaps/ParadisoMap";
import TeranMap from "@/components/ui/VenueMaps/TeranMap";
import {
  KingsLoungeFirstFloor,
  KingsLoungeSecondFloor
} from "@/components/ui/VenueMaps/KingsLoungeMap";
import {
  ClubIvyGroundFloor,
  ClubIvyUpperFloor
} from "@/components/ui/VenueMaps/ClubIvyMap";
import {
  LevelsClubFirstFloor,
  LevelsClubGroundFloor
} from "@/components/ui/VenueMaps/LevelsClub";

export interface MapArea {
  id: string;
  name: string;
  mapId: string;
  map: React.ReactNode;
}

export interface VenueMap {
  id: string;
  slug: string;
  areas: MapArea[];
}

const venueMaps: VenueMap[] = [
  {
    id: "8b3e48ba-9078-4276-9bef-3185193aae69",
    slug: "paradiso",
    areas: [
      {
        name: "Main Area",
        mapId: "paradiso-table-reservation",
        id:
          import.meta.env.VITE_ENV === "prod"
            ? "75af780c-a1b1-4f63-ad02-afaf84f2e061"
            : "5e0db8e8-e131-4855-b232-6906d55c19a2",
        map: <ParadisoMap />
      }
    ]
  },
  {
    id: "37a03d0c-3377-4985-a4b8-bb790542b1cb",
    slug: "teran",
    areas: [
      {
        id: "42542204-8475-4882-a709-e47d5dcec7f2",
        name: "Main Area",
        mapId: "teran-lounge-bar",
        map: <TeranMap />
      }
    ]
  },
  {
    id: "37a03d0c-3377-4985-a4b8-bb790542b1cd",
    slug: "ivy-club",
    areas: [
      {
        id:
          import.meta.env.VITE_ENV === "prod"
            ? "4925c1de-0675-4270-b6f3-ef0389cf3c5f"
            : "cfa47818-6a6e-4489-82e6-538aceb994b5",
        name: "Fround Floor",
        mapId: "club-ivy-ground-floor",
        map: <ClubIvyGroundFloor />
      },
      {
        id:
          import.meta.env.VITE_ENV === "prod"
            ? "668d7f90-fa46-47b9-a969-4bbfd7e534e9"
            : "9e89522f-8f9f-41a7-8d10-1523678b00d9",
        name: "Top Floor",
        mapId: "club-ivy-upper-floor",
        map: <ClubIvyUpperFloor />
      }
    ]
  },
  {
    id: "d2a38ab3-c673-434c-8364-7eeaea11abe0",
    slug: "kings-lounge-pokhara",
    areas: [
      {
        id: "46e3751d-9905-4bc4-a061-4c07521e99c9",
        name: "Main Area",
        mapId: "kings-lounge-table-reservation-1st-floor",
        map: <KingsLoungeFirstFloor />
      },
      {
        id: "5e10737d-02e2-4c37-8901-64f8097a3ab2",
        name: "Second Floor",
        mapId: "kings-lounge-table-reservation-2nd-floor",
        map: <KingsLoungeSecondFloor />
      }
    ]
  },
  {
    id: "37a03d0c-3377-4985-a4b8-bb790552b1cd",
    slug: "levels-club-pokhara",
    areas: [
      {
        id: "98d18edf-0651-4974-a70e-0835f3e10fbf",
        name: "Ground Floor",
        mapId: "Levels-ground-floor",
        map: <LevelsClubGroundFloor />
      },
      {
        id: "13925432-16cf-4698-820c-b8625f70a908",
        name: "First Floor",
        mapId: "Levels-first-floor",
        map: <LevelsClubFirstFloor />
      }
    ]
  }
];

export default venueMaps;
